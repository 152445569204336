<script>
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
import request from '../../../../../../utils/request.js';

export default {
  extends: TablePage,
  components: {
    // Modal,
    Form,
  },

  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      this.params.code = val;
      this.getList();
    },
  },
  data() {
    return {
      requestUrl: '/cps-mall/v1/commodity/classification',
      formName: 'Form',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      requestType: 'GET',
      tableConfig: {
        idKek: 'id',
      },
    };
  },
  created() {
    this.getTableConfig();
  },
  methods: {
    // 获取表格配置信息
    getTableConfig() {
      this.getConfigList('cps_hierarchical_management_list');
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable') {
        if (this.selection.length === 0) {
          this.$message({
            type: 'info',
            message: '请至少选择一条数据',
          });
          return false;
        }
        const params = this.selection.map((item) => ({
          id: item,
          status: val.code === 'enable' ? 1 : 0,
        }));
        request.patch('/cps-mall/v1/commodity/classification/updateStatusBatch', params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.resetTree();
          }
        });
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = {
          code: 'add',
        };
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'edit') {
        // 如果分类层级为启用状态则不可编辑
        if (row.fullName === '1111') {
          this.formConfig = { ...row, code: 'view' };
        } else {
          this.formConfig = { ...row, code: 'edit' };
        }
        this.formName = 'Form';
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      // 查看
      if (val.code === 'view') {
        this.formName = 'Form';
        this.formConfig = { ...row, btnCode: 'view', code: 'view' };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
</script>
