<script>
import tablePageTreeBase from '../components/table_page_tree_base';
// import tablePageTreeBase from '../../../../components/table_page_tree_base';
import TablePage from './table';
import request from '../../../../../utils/request.js';

export default {
  extends: tablePageTreeBase,
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入搜索的分类编码/名称',
      title: '分类层级',
      restful: '/cps-mall/v1/commodity/classification/findTreeByCondition',
    };
  },
  methods: {

  },
};
</script>
