<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';

// import { encodeKey128 } from '../../../../../../utils/index';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单
    };
  },
  async created() {
    const res = await this.getFormRule('cps_hierarchical_management_form');
    // console.log(res);
    // console.log(this.formConfig.id);
  },
  methods: {
    // 表单渲染完成后回调
    async formComplete() {
      const parentId = this.getRule('parentId');
      const parentIdOptions = await request.get(
        '/cps-mall/v1/commodity/classification/findList',
      );
      const option = await parentIdOptions.result.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      parentId.options = option;
      this.getDetail();
    },
    getDetail() {
      if (this.formConfig.code === 'add') return;
      request
        .get('/cps-mall/v1/commodity/classification/findById', { id: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue({ ...result, parentId: result.parentId === '0' ? '' : result.parentId });
          }
        });
    },
    submit() {
      let params = this.getFormData();
      if (this.formConfig.code === 'add') {
        request.post('/cps-mall/v1/commodity/classification', params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      } else {
        params = { ...params, id: this.formConfig.id };
        request.patch('/cps-mall/v1/commodity/classification', params).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
