<template>
  <div class="container">
    <div class="fix-container">
      <div class="title-container">
        <el-button :icon="triggerIcon" @click="trigger" type="text" />
        <span :hidden="!show" class="tittle">{{ title }}</span>
        <el-button :hidden="!show" icon="el-icon-refresh" type="text" @click="reset" />
      </div>
      <div :hidden="!show"  class="search-container">
          <el-input
            :placeholder="placeholder"
            v-model="filterText"
            clearable
            suffix-icon="el-icon-search"
          >
          </el-input>
        </div>
    </div>
    <el-scrollbar
          wrap-class="tree-scrollbar"
          class="tree-style"
          :native="false"
          view-class="tree-scrollr-container"
          :noresize="false"
          tag="div"
        >
    <div class="grid-content" :hidden="!show" ref="elTree">
      <div :hidden="!isNode">
        <el-tree
          class="filter-tree"
          :data="treeData"
          :props="defaultProps"
          highlight-current
          :load="loadNode"
          :lazy="false"
          :indent="2"
          :default-expand-all="false"
          :expand-on-click-node="false"
          node-key="id"
          @node-click="handleCheckChange"
        >
          <span class="el-tree-node__label" slot-scope="{ node }">
            <el-tooltip
              :disabled="calWordWidth(node.label)"
              class="item"
              effect="dark"
              :content="node.label"
              placement="right"
            >
              <div class="tree_label_text">{{ node.label }}</div>
            </el-tooltip>
          </span>
        </el-tree>
      </div>
      <div :hidden="isNode">
        <el-tree
          class="filter-tree"
          :data="treeData"
          :props="defaultProps"
          highlight-current
          :default-expand-all="true"
          :expand-on-click-node="false"
          node-key="id"
          :indent="2"
          @node-click="handleCheckChange"
        >
          <span class="el-tree-node__label" slot-scope="{ node }">
            <el-tooltip
              :disabled="calWordWidth(node.label)"
              class="item"
              effect="dark"
              :content="node.label"
              placement="right"
            >
              <div class="tree_label_text">{{ node.label }}</div>
            </el-tooltip>
          </span>
        </el-tree>
      </div>
    </div>
     </el-scrollbar>
  </div>
</template>

<script>
// import request from '../../utils/request';
import request from '../../../../../../utils/request.js';
import debounce from '../../../../../../utils/debounce';

export default {
  props: {
    value: String,
    restful: String,
    title: String,
    placeholder: {
      type: String,
      default: '请输入组织名称/编码',
    },
    defaultProps: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'name',
          value: 'code',
          isLeaf: 'isLeaf',
        };
      },
    },
  },
  watch: {
    filterText() {
      this.searchDebounce();
    },
  },
  data() {
    return {
      filterText: '',
      treeData: [],
      codes: '',
      defaultExpandedKeys: [],
      defaultExpandAll: false,
      showTree: true,
      searchDebounce: null,
      isNode: true,
      show: true,
      triggerIcon: 'el-icon-d-arrow-left',
    };
  },
  created() {
    this.getTreeList();
  },
  mounted() {
    this.searchDebounce = debounce(() => this.getTreeList(), 500);
  },
  methods: {
    // 计算文字长度
    calWordWidth(word) {
      const con = document.createElement('canvas').getContext('2d');
      con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
      // 生成宽度查询函数
      const strlen = (str) => con.measureText(str).width;
      return strlen(word) < this.$refs.elTree.clientWidth - 50;
    },
    reset() {
      this.$emit('change', '');
      this.getTreeList('reset');
    },
    getTreeList(type) {
      if (type === 'reset') {
        this.filterText = '';
      }
      const data = {
        codeOrName: this.filterText,
      };
      request.get(this.restful, data).then((res) => {
        if (res.success) {
          this.treeData = res.result;
          this.treeData.map((v) => {
            const item = v;
            // item.isLeaf = !item.hasChild;
            return item;
          });
          if (this.filterText !== '') {
            this.isNode = false;
          } else {
            this.isNode = true;
          }
        }
      });
    },
    loadNode(node, resolve) {
      console.log(node);
      if (node.data.hasChild) {
        request
          .post(this.restful, {
            parentCode: node.data.code,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              data.map((v) => {
                const item = v;
                item.isLeaf = !item.hasChild;
                return item;
              });
              resolve(data);
            }
          });
      } else {
        return resolve([]);
      }
    },
    // 控制左边的树显示隐藏
    trigger() {
      this.show = !this.show;
      if (this.show) {
        this.triggerIcon = 'el-icon-d-arrow-left';
      } else {
        this.triggerIcon = 'el-icon-d-arrow-right';
      }
      this.$emit('trigger', this.show);
    },
    handleCheckChange(data) {
      this.codes = data[this.defaultProps.value];
      this.$emit('change', this.codes);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  .fix-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    height: 90px;
    z-index: 99;
    background-color: white;
    padding-bottom: 4px;
    .title-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        .tittle {
          font-size: 14px;
          font-weight: 600px;
          line-height: 26px;
        }
      }
    .search-container {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
  }
  .grid-content{
    margin-top: 90px;
.filter-tree {
    padding-top: 10px;
  }
  }

}
.tree-scrollr-container {
  min-height: 300px;
  padding: 0px !important;
  overflow: hidden;
}
.table-container{
  padding: 0 10px;
}
.tree-style {
  height: calc(100vh - 90px);
}
/deep/.tree-container {
  height: 100%;
}
/deep/.tree-scrollbar {
  overflow-x: hidden;
}
/deep/.el-scrollbar__view {
  height: auto !important;
}
/deep/ .el-tree-node__label {
  overflow: hidden;
  .tree_label_text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    width: 100%;
  }
}
</style>
